footer h1 {
    font-size: 5rem;
}

footer {
    padding-top: 50px;
    padding-bottom: 30px;
    /*color: #6a6a6a;*/
}

.footerLeft {
    padding-top: 95px;
}

footer h3 {
    font-size: 1.4rem;
}

/* Add styles for the flex container */
footer .flexContainer {
    display: flex;
    /*min-height: 200px;  !* Set a minimum height *!*/
}

footer .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /*background-color: red;*/
}