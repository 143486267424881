.flexContainer {
    display: flex;
}

.column {
}

.g5 {
    gap: 5px;
}

.g10 {
    gap: 10px;
}

.g15 {
    gap: 15px;
}

.g20 {
    gap: 20px;
}

.g30 {
    gap: 30px;
}

.g40 {
    gap: 40px;
}

.g50 {
    gap: 50px;
}

.w10 {
    width: 10%;
}

.w15 {
    width: 15%;
}

.w20 {
    width: 20%;
}

.w25 {
    width: 25%;
}

.w30 {
    width: 30%;
}

.w35 {
    width: 35%;
}

.w40 {
    width: 40%;
}

.w45 {
    width: 44%;
}

.w50 {
    width: 50%;
}

.w55 {
    width: 56%;
}

.w60 {
    width: 60%;
}

.w65 {
    width: 65%;
}

.w70 {
    width: 70%;
}

.w75 {
    width: 75%;
}

.w80 {
    width: 80%;
}

.w85 {
    width: 85%;
}

.w90 {
    width: 90%;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*background-color: red;*/
}

.alignCenter {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.spaceBetween {
    justify-content: space-between;
}

.spaceAround {
    justify-content: space-around;
}

.gridContainer2x2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /*grid-template-rows: repeat(2, 1fr);*/
    padding-top: 20px;
}

.gridContainer4x {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /*grid-template-rows: repeat(2, 1fr);*/
}

.gridItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gridItem {
    position: relative;
}

.testing {
    flex: 1;
}
